.FullScreenImage {
  &-fullScreenImageContainer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #000;
    width: 100%;
    min-width: 100%;
    height: 100%;
    gap: var(--app-gridGap);
    max-width: var(--app-maxWidth);
    z-index: 1;
  }

  &-textContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--app-gridGap);

    .App--mobile & {
      width: 100%;
    }
  }

  &-titleDesktop,
  &-titleMobile {
    grid-row: 2;
    font-family: var(--fontFamily-title);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  &-titleDesktop {
    .App--mobile & {
      display: none;
    }
  }

  &-titleMobile {
    align-self: flex-start;

    .App--desktop & {
      display: none;
    }
  }

  &-carouselAndTextContainer {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .App--mobile & {
      height: 100%;
      max-width: unset;
      justify-content: space-between;
      margin: var(--app-gridGap);
      align-items: center;
    }

    img {
      object-fit: contain;
      max-height: 90vh;
    }
  }

  &-carouselAndButtonsContainer {
    display: flex;
    align-items: center;

    .App--mobile & {
      display: block;
    }
  }

  &-closeButtonContainer {
    .App--mobile & {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  &-controlButton {
    background: none;
    border: none;
    color: #fff;

    &[disabled] {
      opacity: 0.6;
    }
  }

  &-controlButtonPrevDesktop,
  &-controlButtonNextDesktop {
    cursor: pointer;

    .App--mobile & {
      display: none;
    }
  }

  &-controlButtonPrevMobile,
  &-controlButtonNextMobile {
    .App--desktop & {
      display: none;
    }
  }

  &-closeButton {
    border-bottom: 1px solid var(--White, #fff);
    cursor: pointer;
  }
}
