.Phase3SectionExperienceMobile {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  height: 100%;
  max-width: var(--app-maxWidth);
  width: 100%;

  &-text {
    font-family: var(--fontFamily-title);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  &-images {
    align-items: stretch;
    display: flex;
    height: 30vh;
    max-height: 285px;
    min-height: 120px;
    overflow: scroll hidden;
    position: relative;
    scroll-snap-type: x mandatory;
    width: 100%;
  }

  &-imageContainer {
    flex-basis: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
  }

  &-image {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  &-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: var(--app-gridGap);
  }

  &-controlButtons {
    align-items: center;
    display: flex;
    gap: 12px;
  }

  &-controlButtonNext,
  &-controlButtonPrev {
    background-color: transparent;
    border: 0 none;
    color: currentcolor;
    padding: 0;

    & > * {
      display: block;
    }

    &:disabled {
      opacity: 0.38;
    }
  }

  &-galleryMap {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &-galleryMapItem {
    background-color: white;
    height: 8px;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    width: 8px;

    &[data-active='true'] {
      opacity: 1;
      width: 24px;
    }
  }

  &-galleryText {
    padding-right: var(--app-gridGap);
  }
}
