.Phase2SectionCollectionDesktop {
  box-sizing: border-box;
  display: grid;
  gap: 32px var(--app-gridGap);
  grid-template-columns: var(--app-gridColumns);
  height: 100%;
  max-width: var(--app-maxWidth);
  padding: 0 var(--app-gridGap);
  position: relative;
  width: 100%;

  &-container1 {
    grid-column: 3 / span 3;
    grid-row: 1;
    position: relative;
  }

  &-container2 {
    grid-column: -5 / span 3;
    grid-row: 1;
    position: relative;
  }

  &-subContainer2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    top: 27.28vh;
  }

  &-container3 {
    grid-column: 4 / span 4;
    grid-row: 1;
    position: relative;
  }

  &-nftCollectionText {
    grid-column: 2 / span 6;
    grid-row: 1;
    position: absolute;
    top: 24.69vh;
  }

  &-image1,
  &-image2,
  &-image3 {
    display: block;
    height: auto;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  &-image1 {
    aspect-ratio: 330 / 440;
    right: calc((1 - var(--scrollProgress)) * 50vw);
    top: -9.5vh;
  }

  &-image2 {
    aspect-ratio: 1 / 1;
    left: calc((1 - var(--scrollProgress)) * 25vw);
  }

  &-video {
    bottom: 0;
    position: absolute;
    left: calc((1 - var(--scrollProgress)) * -20vw);
    width: 80%;
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-size: 96px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;
  }

  &-text {
    opacity: var(--scrollProgress);
  }
}
