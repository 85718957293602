.Phase3Main {
  --languageSwitcher-width: 0;

  align-items: center;
  align-self: stretch;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 13px;
  height: 100vh;
  letter-spacing: 0.002em;
  line-height: 1.4615;
  overflow: hidden;
  position: relative;

  .App--mobile & {
    background-position: top right 26.5%;
    font-size: 12px;
    gap: 24px;
  }

  &-header {
    box-sizing: border-box;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &-socialLinks {
    --SocialLinks-flexDirection: column;

    bottom: var(--app-gridGap);
    left: var(--app-gridGap);
    position: fixed;

    .App--mobile & {
      display: none;
    }
  }

  &-languageSwitcher {
    bottom: var(--app-gridGap);
    position: fixed;
    right: var(--app-gridGap);

    .App--mobile & {
      display: none;
    }
  }

  &-body {
    align-items: stretch;
    gap: 32px;
    height: 100%;
    overflow: auto;
    scroll-snap-type: y mandatory;
    width: 100%;
  }

  &-section {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    scroll-snap-align: start;
  }

  &-pagerContainer {
    align-items: center;
    display: flex;
    height: 100%;
    padding-right: 24px;
    pointer-events: none;
    position: fixed;
    right: 0;
  }
}
