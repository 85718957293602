.Phase3SectionWatchesDesktop {
  &-body {
    box-sizing: border-box;
    display: grid;
    flex: 1;
    align-items: center;
    gap: var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;
  }

  &-titleContainer,
  &-descriptionContainer {
    position: relative;
  }

  &-titleContainer {
    grid-column: 1 / 4;
  }

  &-title,
  &-titleBigText {
    text-transform: uppercase;
    font-family: var(--fontFamily-title);
    font-size: 3rem;
    line-height: 0.9;
    word-spacing: 40vh;
  }

  &-titleBigText {
    font-size: 5rem;
  }

  &-carouselContainer {
    grid-column: 5 / 9;
  }

  /* stylelint-disable-next-line  selector-class-pattern */
  .slide img {
    width: auto;
    max-width: 28rem;
    max-height: 86vh;
  }

  /* stylelint-disable-next-line  selector-class-pattern */
  &-body.full-height {
    /* stylelint-disable-next-line  selector-class-pattern */
    .slide img {
      max-width: 26rem;
      max-height: 72vh;
    }
  }

  &-descriptionContainer {
    height: 100%;
    grid-column: 9 / 12;
  }

  &-description {
    height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--fontFamily-title);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: normal;
  }

  &-restartExperienceContainer {
    position: absolute;
    bottom: -6%;
  }

  &-restartExperienceButton {
    --button-textHeight: auto;

    margin-top: 2rem;
    font-family: var(--fontFamily-primary);
    font-weight: 400;
    text-transform: unset;
    font-size: 13px;
  }
}
