.Phase3SectionWatches {
  --hexagonBorderWidth: 1px;
  --activeHexagonBorderWidth: 2px;

  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  position: relative;

  /* stylelint-disable-next-line  selector-class-pattern */
  &.full-height {
    height: 100%;
  }

  &::before {
    content: '';
    background-image: url('./background.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.15s ease-in-out;
  }

  &-gradient {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 0;

    .App--mobile & {
      padding-top: var(--app-gridGap);
      justify-content: space-between;
    }
  }

  &-arch {
    position: absolute;
    border: 1px solid white;
    border-radius: 100vw;
    width: 80vw;
    left: 65%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100vh;
  }

  &-vertContainer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .App--desktop & {
      grid-column: -5 / span 4;
      justify-content: center;
    }

    .App--mobile & {
      grid-column: 2 / span 3;
    }
  }

  &-vertImage {
    aspect-ratio: auto 620 / 635;
  }

  &-vertSlogan {
    aspect-ratio: auto 244 / 17;
  }

  &-vertImage,
  &-vertSlogan {
    display: block;
    height: auto;
    width: 100%;

    .App--desktop & {
      max-width: 21vw;
    }

    .App--mobile & {
      max-width: 175px;
    }
  }

  &-footer {
    align-items: flex-end;
    align-self: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: var(--app-gridGap);
  }

  &-footerContentCenter {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .App--desktop & {
      grid-column: 2;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  /*
    This is mostly duplication done for extracting `/watches` page as a separate page.
    It will probably unneeded later and should be removed
  */
  &-footerSeparatePage {
    align-items: center;
    align-self: stretch;
    display: grid;
    position: relative;
    grid-template-columns: var(--app-gridColumns);
    padding: var(--app-gridGap);
    gap: var(--app-gridGap);

    .App--desktop & {
      margin-bottom: 6px;
    }
  }

  &-footerLinksAndLanguage {
    align-items: center;
    display: flex;
    grid-column: 1 / -1;
    justify-content: space-between;

    .App--desktop & {
      grid-row: 2;
      justify-content: flex-end;
    }

    .App--mobile & {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &-socialLinks {
    .App--desktop & {
      --SocialLinks-flexDirection: column;

      position: absolute;
      left: 0;
      bottom: 1rem;
      grid-column: 1 / span 2;
    }
  }

  &-footerLegalAndLanguage {
    align-items: center;
    display: flex;
    gap: var(--app-gridGap);
  }

  &-footerDisclaimer {
    font-size: 11px;
    opacity: 0.6;
    text-align: center;

    .App--desktop & {
      grid-column: 2 / -2;
      grid-row: 1;
    }

    .App--mobile & {
      grid-column: 1 / -1;
    }

    a {
      font-weight: 700;
    }
  }

  &-footerMadeBy {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-self: center;
    font-size: 11px;

    .App--mobile & {
      grid-column: span 4;
    }

    .App--desktop & {
      grid-row: 2;
      grid-column: 5 / span 4;
    }
  }
}
