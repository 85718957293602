.Phase3ScrollPager {
  display: flex;
  flex-direction: column;
  line-height: 1;
  text-align: end;
  text-transform: uppercase;

  &-pages {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-block-end: 18px;
    margin-block-start: 16px;
  }

  &-page {
    background-color: currentcolor;
    height: 1px;
    transition: all 0.2s ease-in-out;
    width: 4px;

    &--active {
      height: 2px;
      width: 16px;
    }
  }

  &-lastPage {
    opacity: 0.48;
  }
}
