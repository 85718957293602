.Phase2MadeBySoil {
  align-items: center;
  display: flex;
  font-size: 11px;
  gap: 8px;
  line-height: 1.7273;

  &-soilLogo {
    aspect-ratio: auto 59 / 20;
    display: block;
    height: 20px;
  }
}
