.Phase2SectionCollectionNftCollectionText {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: var(--fontFamily-title);
  font-weight: 400;
  line-height: 0.8;
  text-transform: uppercase;

  @media (height <= 600px) {
    align-items: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .App--desktop & {
    font-size: 96px;
  }

  .App--mobile & {
    font-size: 48px;

    @media (height <= 600px) {
      font-size: 32px;
    }
  }

  &-image {
    aspect-ratio: auto 333 / 223;

    .App--desktop & {
      margin-left: 10px;
      width: 333px;
    }

    .App--mobile & {
      height: 15vh;
      margin-left: 5px;
      max-height: 120px;
      min-height: 36px;

      @media (width <= 350px) {
        height: 10vh;
      }
    }
  }

  &-text {
    .App--desktop & {
      margin-top: 16px;
    }

    .App--mobile & {
      margin-top: 16px;
    }
  }
}
