.Phase2VideoPlayer {
  background-color: black;

  &-body {
    height: 100%;
    position: relative;
    width: 100%;
  }

  &-video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &-controls {
    align-items: flex-end;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    position: absolute;
    width: 100%;
  }

  &-buttonMute {
    position: relative;
  }

  &--muted &-buttonMute::after {
    background-image: url('./cross.svg');
    background-size: cover;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
