.DateTimeSelect {
  display: flex;
  flex-direction: column;

  &-label {
    font-size: 10px;
    line-height: 0.8;
  }

  &-inputs {
    align-items: end;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  &-selectWrapper {
    color: currentcolor;
    display: grid;
    grid-template-areas: 'select';
    align-items: center;
    position: relative;
    border-bottom: 1px solid currentcolor;
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1;

    select,
    &::after {
      grid-area: select;
    }

    // Custom arrow
    &::after {
      border-bottom: 1px solid currentcolor;
      border-right: 1px solid currentcolor;
      content: '';
      height: 7px;
      justify-self: end;
      margin-right: 3px;
      transform: rotate(45deg);
      width: 7px;
    }

    .App--mobile & {
      font-size: 12px;
      line-height: 1.3333;
    }

    &:has(> :disabled) {
      opacity: 0.48;
    }
  }

  &-select {
    appearance: none;
    background-color: transparent;
    border: none;
    color: currentcolor;
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;
    padding-block: 7px;
    padding-inline: 0 14px;
    width: 100%;

    &::-ms-expand {
      display: none;
    }
  }

  &-select:focus + &-selectFocus {
    position: absolute;
    border-left: 1px solid white;
    border-radius: inherit;
  }

  option {
    background: initial;
    color: initial;
  }

  &-labelInvisible:not(:focus, :active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
