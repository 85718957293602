.SocialLinks {
  --flexDirection: var(--SocialLinks-flexDirection, row);

  align-items: center;
  color: currentcolor;
  display: flex;
  flex-direction: var(--flexDirection);
  gap: 16px;

  .App--mobile & {
    gap: 8px;
  }

  &-icon {
    color: currentcolor;
    display: block;
    height: 24px;
    width: 24px;
  }

  &-labelInvisible:not(:focus, :active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
