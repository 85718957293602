.Phase2SectionRetailEvent {
  align-items: stretch;
  background-image: url('../../background-monument.jpg');
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .App--desktop & {
    // background-attachment: fixed;
    background-position: center;
  }

  .App--mobile & {
    background-position: top right 26.5%;
  }

  &-gradient {
    align-items: center;
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 46.1%,
      var(--color-brandRed) 100%
    );
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    padding-top: var(--app-headerHeight);
  }

  &-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;

    .App--desktop & {
      justify-content: flex-start;
      margin-top: 15vh;
    }

    .App--mobile & {
      justify-content: center;
    }
  }

  &-content {
    align-items: start;
    display: grid;
    gap: 16px var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;
    margin: 0;

    .App--desktop & {
      font-size: 96px;
      grid-column: 2 / span 6;
    }

    .App--mobile & {
      font-size: 48px;
      grid-column: span 4;
    }
  }

  &-text {
    .App--desktop & {
      grid-column: 2 / span 4;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-continents {
    display: flex;

    .App--desktop & {
      gap: 48px;
      grid-column: 2 / span 10;
      margin-top: 32px;
    }

    .App--mobile & {
      flex-direction: column;
      gap: 32px;
      grid-column: span 4;
      margin-top: 16px;
    }
  }

  &-continent {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-continentName {
    font-family: var(--fontFamily-title);
    font-size: 24px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;
  }

  &-locations i {
    font-style: normal;
    opacity: 0.72;
  }

  &-footer {
    align-items: end;
    align-self: stretch;
    display: grid;
    grid-template-columns: var(--app-gridColumns);
    padding: var(--app-gridGap);
    gap: var(--app-gridGap);

    .App--desktop & {
      margin-bottom: 6px;
    }
  }

  &-footerLinksAndLanguage {
    align-items: center;
    display: flex;

    .App--desktop & {
      grid-column: 9 / span 4;
      grid-row: 2;
      justify-content: flex-end;
    }

    .App--mobile & {
      grid-column: span 4;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &-socialLinks {
    .App--desktop & {
      display: none;
    }

    .App--mobile & {
      grid-column: span 2;
    }
  }

  &-footerLegalAndLanguage {
    align-items: center;
    display: flex;
    gap: var(--app-gridGap);

    .App--desktop & {
      padding-right: calc(var(--app-gridGap) + var(--languageSwitcher-width));
    }
  }

  .App--desktop &-languageSwitcher {
    display: none;
  }

  &-footerMadeBy {
    justify-self: center;

    .App--desktop & {
      grid-column: 5 / span 4;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-footerDisclaimer {
    font-size: 11px;
    opacity: 0.6;
    text-align: center;

    .App--desktop & {
      grid-column: 2 / -2;
    }

    .App--mobile & {
      grid-column: 1 / -1;
    }

    a {
      font-weight: 700;
    }
  }
}
