.Phase2RegistrationLink {
  align-items: center;
  display: flex;
  gap: 17px;
  text-decoration: none;

  &-text {
    line-height: 1;
    text-align: right;
    text-transform: uppercase;
  }

  &-textRow1 {
    opacity: 0.5;
  }

  &-textRow2 {
    font-weight: 700;
  }

  &-figure {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 40px;
  }

  &-octagon {
    height: 100%;
    position: absolute;
    top: 0;
    transition: color 0.2s ease-in-out;
    width: 100%;
  }

  &-icon {
    color: var(--color-brandRed);
    transition: color 0.2s ease-in-out;
    z-index: 0;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) &-octagon {
      color: var(--color-brandRed);
    }

    &:hover:not(:disabled) &-icon {
      color: white;
    }
  }
}
