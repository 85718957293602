.CookiesConsentBanner {
  bottom: 0;
  box-sizing: border-box;
  max-width: 1140px;
  padding: 0 var(--app-gridGap);
  position: fixed;
  width: 100%;
  z-index: 210;

  &-body {
    align-items: stretch;
    background-color: white;
    border: 1px solid var(--color-brandRed);
    color: var(--color-brandRed);
    display: flex;
    margin: 0 auto;
  }

  &-textBlock {
    padding: 1.25rem;
    font-size: 0.75rem;
    border-right: 1px solid var(--color-brandRed);
    line-height: 1.8;
  }

  &-buttonRequired,
  &-buttonAll {
    border-radius: 0;
    border: none;
    font-size: 0.875rem;
    line-height: 1;
    padding: 1.875rem;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
  }

  &-buttonRequired {
    background-color: white;
    color: var(--color-brandRed);

    &:active:not(:disabled),
    &:active:not(:disabled):hover {
      background-color: var(--color-brandRed);
      color: white;
    }
  }

  &-buttonAll {
    background-color: var(--color-brandRed);
    color: white;

    &:active:not(:disabled),
    &:active:not(:disabled):hover {
      background-color: white;
      color: var(--color-brandRed);
    }
  }
}
