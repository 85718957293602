.Phase2SectionRegistrationForm {
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  .App--mobile & {
    gap: 32px;
  }

  .App--desktop & {
    max-width: 448px;
    width: 100%;
  }

  &--fading {
    opacity: 0;
  }

  &-result {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px var(--app-gridGap);
  }

  &-resultTitle {
    font-family: var(--fontFamily-title);
    font-size: 48px;
    font-weight: 400;
    line-height: 0.9;
    text-transform: uppercase;

    .App--desktop & {
      grid-column: span 3;
    }

    .App--mobile & {
      font-size: 32px;
      grid-column: span 4;
    }
  }

  &-resultText {
    .App--desktop & {
      grid-column: span 3;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-size: 42px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;

    .App--mobile & {
      font-size: 48px;
      max-width: 200px;
    }
  }

  &-text {
    &,
    strong {
      font-weight: 500;
    }

    .App--desktop & {
      display: none;
    }
  }

  &-fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
  }

  .App--desktop &-text,
  .App--desktop &-fieldset {
    max-width: 330px;
  }

  &-fieldsetFlex {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .App--mobile & {
      gap: 32px;
    }
  }

  &-inputTerms {
    font-size: 13px;
    line-height: 1.4615;

    .App--mobile & {
      font-size: 12px;
      line-height: 1.3333;
    }
  }

  &-errorMessage {
    margin: 0;
    font-weight: 700;
  }

  &-buttonSubmit {
    align-self: flex-start;
  }

  a {
    font-weight: 700;
  }
}
