.Phase3SectionExperience {
  align-items: center;
  background: var(--color-brandRed);
  display: flex;
  flex-direction: column;

  .App--desktop & {
    gap: 2rem;
  }

  .App--mobile & {
    justify-content: space-between;
  }

  &-body {
    box-sizing: border-box;
    display: grid;
    gap: 32px var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
    height: 100%;
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    position: relative;
    width: 100%;

    .App--mobile & {
      gap: 0.5rem var(--app-gridGap);
      height: auto;
    }
  }

  &-footerContainer {
    box-sizing: border-box;
    display: grid;
    position: relative;
    width: 100%;
    grid-row: 2;
    grid-column: 1 / -1;

    .App--desktop & {
      grid-template-columns: var(--app-gridColumns);
      grid-template-rows: repeat(3, 1fr);
      padding: 0 var(--app-gridGap);
    }

    .App--mobile & {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 0.25rem;
      padding: 0 var(--app-gridGap);
      margin-bottom: 0.5rem;
    }
  }

  &-footerDisclaimer {
    grid-column: 1 / -1;
    justify-self: center;
    font-size: 11px;
    opacity: 0.6;
    text-align: center;

    .App--mobile & {
      grid-row: 2;
    }
  }

  &-socialLinks {
    .App--desktop & {
      display: none;
    }
  }

  &-footerLinksAndLanguage {
    grid-column: 9 / -1;
    grid-row: 2;

    .App--mobile & {
      grid-row: 1;
      grid-column: 1 / -1;
      display: flex;
      justify-content: space-between;
    }
  }

  &-footerMadeBy {
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 0.5rem;
    font-size: 11px;

    .App--mobile & {
      grid-column: 1 / span 4;
      grid-row: 3;
    }

    .App--desktop & {
      grid-row: 2;
      grid-column: 5 / span 4;
    }
  }

  &-content {
    display: grid;
    gap: 24px var(--app-gridGap);
    grid-row: 1;

    .App--desktop & {
      position: absolute;
      grid-column: 2 / span 6;
      grid-template-columns: repeat(6, 1fr);
      top: 30vh;
    }

    .App--mobile & {
      gap: 8px var(--app-gridGap);
      grid-column: span 4;
      grid-template-columns: repeat(4, 1fr);
      padding-top: var(--app-headerHeight);
    }
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-size: 96px;
    font-weight: 400;
    line-height: 0.8;
    margin: 0;
    text-transform: uppercase;

    .App--desktop & {
      grid-column: span 6;
    }

    .App--mobile & {
      grid-column: span 4;
      margin-top: 5vh;
      line-height: 0.9;
    }

    strong {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px currentcolor;
      font-weight: 400;

      .App--desktop & {
        font-size: 160px;
        line-height: 0.9;
      }
    }
  }

  &-text {
    font-size: 13px;

    .App--desktop & {
      grid-column: span 3;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-gallery {
    .App--desktop & {
      grid-column: 7 / -1;
      grid-row: 1;
      justify-content: flex-end;
    }

    .App--mobile & {
      grid-column: span 4;
      gap: 0;
    }
  }
}
