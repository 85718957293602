.Phase1NftWallet {
  display: grid;
  gap: 6vh var(--app-gridGap);
  grid-template-columns: var(--app-gridColumns);
  height: 100%;
  padding: 0 var(--app-gridGap);

  &-areaVert {
    align-self: center;
    justify-self: center;

    .App--desktop & {
      grid-column: 4 / span 3;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-areaOutlet {
    align-self: center;

    .App--desktop & {
      grid-column: 8 / span 4;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-vert {
    aspect-ratio: auto 330 / 400;
    width: 100%;

    .App--mobile & {
      height: 23vh;
      width: auto;
    }
  }
}
