.Phase2SectionIntro {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  position: relative;

  &::before {
    content: '';
    background-image: url('./background.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.15s ease-in-out;

    .App--desktop & {
      background-position: center;
    }

    .App--mobile & {
      background-position: top left 47.5%;
      transform: scale(2);
    }
  }

  &-gradient {
    align-items: center;
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 70.62%,
      var(--color-brandRed) 100%
    );
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: var(--app-headerHeight);
    z-index: 0;
  }

  &-body {
    box-sizing: border-box;
    display: grid;
    flex: 1;
    gap: var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;
  }

  &-vertContainer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .App--desktop & {
      grid-column: -5 / span 3;
    }

    .App--mobile & {
      grid-column: span 4;
      padding-top: 5.373vh;
    }
  }

  &-vertImage {
    aspect-ratio: auto 620 / 635;
  }

  &-vertSlogan {
    aspect-ratio: auto 244 / 17;
  }

  &-vertImage,
  &-vertSlogan {
    display: block;
    height: auto;
    width: 100%;

    .App--mobile & {
      max-width: 184px;
    }
  }

  &-footer {
    align-items: flex-end;
    align-self: stretch;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: var(--app-gridGap);
  }

  &-footerContentRight {
    .App--mobile & {
      display: none;
    }
  }

  &-footerContentCenter {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .App--desktop & {
      grid-column: 2;
    }

    .App--mobile & {
      grid-column: span 3;
    }
  }

  &-buttonDiscover {
    margin-left: auto;
    margin-right: calc(var(--languageSwitcher-width) + var(--app-gridGap));
  }
}
