.Checkbox {
  align-items: flex-start;
  display: flex;
  gap: 8px;

  .App--mobile & {
    gap: 16px;
  }

  &-input {
    align-items: center;
    appearance: none;
    background: rgb(0 0 0 / 8%);
    border: 1px solid currentcolor;
    border-radius: 0;
    color: currentcolor;
    display: flex;
    flex-shrink: 0;
    height: 12px;
    justify-content: center;
    margin-top: 5px;
    transition: opacity 0.2s ease-in-out;
    width: 12px;

    &:disabled {
      opacity: 0.48;
    }

    &::before {
      box-shadow: inset 8px 8px currentcolor;
      content: '';
      height: 8px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      width: 8px;
    }

    .App--mobile & {
      height: 14px;
      margin-top: 0;
      width: 14px;
    }

    &:checked::before {
      opacity: 1;
    }
  }
}
