.Phase1Legal {
  flex: 1;
  max-width: var(--app-maxWidth);
  overflow: auto;
  padding: 0 var(--app-gridGap);

  p {
    margin-block-end: 0;
    margin-block-start: 1em;

    &:first-child {
      margin-block-start: 0;
    }
  }

  h1,
  h2 {
    font-family: var(--fontFamily-title);
    font-weight: 400;
    line-height: 0.8;
    margin-block-end: 0;
    margin-block-start: 1em;
    text-transform: uppercase;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  ol,
  ul {
    list-style-position: inside;
    padding-left: 0;
  }

  ol[data-level2],
  ol ol,
  ul ol {
    list-style-type: decimal-in-brackets;
  }
}

@counter-style decimal-in-brackets {
  prefix: '(';
  suffix: ') ';
  symbols: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
  system: numeric;
}
