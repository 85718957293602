.Phase2SectionCollection {
  align-items: center;
  background: var(--color-brandRed);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-body {
    flex: 1;
  }
}
