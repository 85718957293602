.Phase2ContentPage {
  align-items: stretch;
  align-self: stretch;
  background-attachment: fixed;
  background-image: url('../background-monument.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 13px;
  gap: var(--app-gridGap);
  letter-spacing: 0.002em;
  line-height: 1.4615;

  &-header {
    z-index: 100;

    .App--desktop & {
      height: auto;
    }
  }

  &--menuVisible {
    height: 100vh;
  }

  &-content {
    align-self: center;
    flex: 1;
    box-sizing: border-box;
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;
  }

  &--menuVisible &-content {
    overflow: hidden;
  }

  &[data-name='watches'] &-content {
    position: absolute;
    min-width: 100%;
    height: 100%;
    padding: 0;
  }

  &-footer {
    align-items: center;
    align-self: stretch;
    display: grid;
    grid-template-columns: var(--app-gridColumns);
    padding: var(--app-gridGap);
    gap: var(--app-gridGap);

    .App--desktop & {
      margin-bottom: 6px;
    }
  }

  &-footerLinksAndLanguage {
    align-items: center;
    display: flex;
    grid-column: 1 / -1;
    justify-content: space-between;

    .App--desktop & {
      grid-row: 2;
    }

    .App--mobile & {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  &-socialLinks {
    .App--desktop & {
      grid-column: 1 / span 2;
    }
  }

  &-footerLegalAndLanguage {
    align-items: center;
    display: flex;
    gap: var(--app-gridGap);
  }

  &-languageSwitcher {
    flex-shrink: 0;
  }

  &-footerDisclaimer {
    font-size: 11px;
    opacity: 0.6;
    text-align: center;

    .App--desktop & {
      grid-column: 2 / -2;
      grid-row: 1;
    }

    .App--mobile & {
      grid-column: 1 / -1;
    }

    a {
      font-weight: 700;
    }
  }

  &-footerMadeBy {
    justify-self: center;

    .App--mobile & {
      grid-column: span 4;
    }

    .App--desktop & {
      grid-row: 2;
      grid-column: 5 / span 4;
    }
  }

  p {
    margin-block-end: 0;
    margin-block-start: 1em;

    &:first-child {
      margin-block-start: 0;
    }
  }

  h1,
  h2 {
    font-family: var(--fontFamily-title);
    font-weight: 400;
    line-height: 0.8;
    margin-block-end: 0;
    margin-block-start: 1em;
    text-transform: uppercase;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  ol,
  ul {
    list-style-position: inside;
    padding-left: 0;
  }

  &-nestedList,
  ol ol,
  ul ol {
    list-style-type: decimal-in-brackets;
  }
}

@counter-style decimal-in-brackets {
  prefix: '(';
  suffix: ') ';
  symbols: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
  system: numeric;
}
