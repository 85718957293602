.Button {
  align-items: center;
  background: rgb(0 0 0 / 10%);
  border: 1px solid currentcolor;
  color: currentcolor;
  font-family: var(--fontFamily-title);
  font-size: 32px;
  font-weight: 400;
  gap: 10px;
  line-height: 0.9375;
  overflow: hidden;
  padding: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;

  &:not([hidden]) {
    display: flex;
  }

  &--with-content {
    padding: 8px 10px;
  }

  &-icon {
    height: 20px;
    width: 20px;
  }

  &--with-content &-icon {
    height: 24px;
    width: 24px;
  }

  &-text {
    height: var(--button-textHeight, 24px);
  }

  &:disabled {
    opacity: 0.48;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: var(--color-brandRed);
    }
  }

  &:active:not(:disabled),
  &:active:not(:disabled):hover {
    background-color: white;
    border-color: white;
    color: var(--color-brandRed);
  }
}
