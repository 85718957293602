.Phase1Form {
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  .App--mobile & {
    gap: 32px;
  }

  .App--desktop & {
    gap: 48px;
    max-width: 448px;
    width: 100%;
  }

  &--fading {
    opacity: 0;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .App--mobile & {
      gap: 8px;
    }
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-size: 42px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;

    .App--mobile & {
      font-size: 32px;
    }
  }

  &-fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
  }

  &-text {
    font-weight: 400;

    strong {
      white-space: nowrap;
    }
  }

  .App--desktop &-text,
  .App--desktop &-fieldset {
    max-width: 330px;
  }

  &-fieldsetFlex {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .App--mobile & {
      gap: 32px;
    }
  }

  &-inputTerms {
    font-size: 11px;
    line-height: 1.7273;

    .App--mobile & {
      font-size: 12px;
      line-height: 1.3333;
    }
  }

  &-buttonSubmit {
    .App--desktop & {
      align-self: flex-start;
      margin-top: 24px;
    }

    .App--mobile & {
      align-self: center;
    }
  }

  a {
    font-weight: 700;
  }
}
