.Input {
  display: flex;
  flex-direction: column;

  &-label {
    font-size: 10px;
    line-height: 0.8;
  }

  &-input {
    background: transparent;
    border-bottom: 1px solid currentcolor;
    border-left: 0 none;
    border-right: 0 none;
    border-top: 0 none;
    border-radius: 0;
    color: inherit;
    font-size: 14px;
    line-height: 1;
    outline: none;
    padding: 7px 0;
    transition: opacity 0.2s ease-in-out;

    .App--mobile & {
      font-size: 12px;
      line-height: 1.3333;
    }

    &:disabled {
      opacity: 0.48;
    }
  }
}
