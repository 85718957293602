.LanguageSwitcher {
  align-items: center;
  border-bottom: 1px solid rgb(255 255 255 / 50%);
  border-radius: 0;
  color: currentcolor;
  display: none;
  font-size: 13px;
  grid-template-areas: 'select';
  line-height: 1;
  padding: 0;
  position: relative;
  width: var(--languageSwitcher-width);

  &-select,
  &::after {
    grid-area: select;
  }

  // Custom arrow
  &::after {
    border-right: 2px solid currentcolor;
    border-top: 2px solid currentcolor;
    content: '';
    height: 7px;
    justify-self: end;
    margin-right: 3px;
    margin-top: 4px;
    pointer-events: none;
    transform: rotate(-45deg);
    width: 7px;
  }

  .App--mobile & {
    font-size: 12px;
    line-height: 1.3333;
  }

  &:has(> :disabled) {
    opacity: 0.48;
  }

  &-select {
    appearance: none;
    background-color: transparent;
    border: none;
    color: currentcolor;
    cursor: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;
    text-transform: uppercase;
    width: 100%;

    .App--desktop & {
      padding: 8px 16px 8px 0;
    }

    .App--mobile & {
      padding: 6px 16px 6px 0;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &-select:focus + &-selectFocus {
    position: absolute;
    border-left: 1px solid white;
    border-radius: inherit;
  }

  option {
    background: initial;
    color: initial;
  }

  &-labelInvisible:not(:focus, :active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
