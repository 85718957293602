.Phase2SectionTrailer {
  align-items: center;
  background: var(--color-brandRed);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--app-headerHeight);

  &-body {
    align-items: end;
    box-sizing: border-box;
    display: grid;
    gap: 32px var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;
  }

  &-videoContainer {
    align-items: center;
    aspect-ratio: auto 802 / 510;
    background-image: url('./video-preview.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;

    .App--desktop & {
      grid-column: 5 / span 7;
      grid-row: 1;
    }

    .App--mobile & {
      grid-column: span 4;
    }
  }

  &-previewImage {
    aspect-ratio: auto 802 / 510;
    width: 100%;
  }

  &--playing &-buttonWatch {
    display: none;
  }

  &-content {
    display: grid;
    gap: 16px var(--app-gridGap);
    grid-row: 1;

    .App--desktop & {
      grid-column: 2 / span 5;
      grid-template-columns: repeat(5, 1fr);
      padding-bottom: 26px;
    }

    .App--mobile & {
      grid-column: span 4;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-weight: 400;
    line-height: 0.8;
    margin: 0;
    text-transform: uppercase;

    .App--desktop & {
      font-size: 96px;
      grid-column: span 5;
    }

    .App--mobile & {
      font-size: 48px;
      grid-column: span 4;
    }
  }

  &-text {
    grid-column: span 4;
  }

  &-videoOverlay {
    align-items: center;
    background: black;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 10vh 10vw;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 300;

    &:not([hidden]) {
      display: flex;
    }
  }

  &-video {
    max-height: 100%;
    width: 100%;
  }

  &-buttonCloseVideo {
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    bottom: var(--app-gridGap);
    color: currentcolor;
    cursor: pointer;
    font-weight: 700;
    padding: 0 0 5px;
    position: absolute;
  }
}
