.Phase3SectionWatchesMobile {
  &-body {
    box-sizing: border-box;
    display: grid;
    flex: 1;
    align-items: center;
    gap: var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;
  }

  &-carouselContainer {
    grid-column: 1 / span 4;

    img {
      max-width: 12rem;
    }
  }

  /* stylelint-disable-next-line  selector-class-pattern */
  &-body.full-height {
    img {
      max-width: min(15rem, 28vw);
    }
  }

  &-bottomContainer {
    grid-column: 1 / span 4;
    display: grid;
    grid-template-columns: var(--app-gridColumns);
    position: relative;
  }

  &-textContainer {
    grid-column: 1 / span 3;
  }

  &-title {
    font-size: 2.25rem;
    text-transform: uppercase;
    font-family: var(--fontFamily-title);
    line-height: 0.9;
    margin: 1rem auto 0.5rem;
  }

  &-description {
    font-size: 13px;
    font-family: var(--fontFamily-primary);
  }

  &-colorPickerContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &-colorPickerItem {
    width: 40px;
    height: 40px;
  }

  &-footer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }
}
