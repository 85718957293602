.MobileMenu {
  background-color: var(--color-brandRed);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 100%;
  overflow: auto;
  padding: var(--app-headerHeight) var(--app-gridGap) var(--app-gridGap);
  position: fixed;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &--visible {
    left: 0;
  }

  &-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: var(--fontFamily-title);
    font-size: 48px;
    font-weight: 400;
    gap: 0.6667em;
    line-height: 0.8;
    margin-top: 1.04em;
    padding-right: 20px;
    text-transform: uppercase;
    transition: font-size 0.2s ease-in-out;

    @media (630px <= height < 730px) {
      font-size: 40px;
    }

    @media (530px <= height < 630px) {
      font-size: 32px;
    }

    @media (430px <= height < 530px) {
      font-size: 24px;
    }

    @media (height < 430px) {
      font-size: 16px;
    }
  }

  &-spacer {
    background-color: currentcolor;
    height: 2px;
    width: 16px;
  }

  &-navLink {
    text-decoration: none;
  }

  &-footer {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 32px;
    transition: gap 0.2s ease-in-out;

    @media (530px <= height < 630px) {
      gap: 24px;
    }

    @media (430px <= height < 530px) {
      gap: 16px;
    }

    @media (height < 430px) {
      gap: 8px;
    }
  }

  &-footerRow1,
  &-footerRow2,
  &-footerRow3 {
    align-items: center;
    display: flex;
  }

  &-footerRow2 {
    flex-wrap: wrap;
    gap: 32px 0;
    justify-content: space-between;
  }

  &-footerRow3 {
    justify-content: center;
  }
}
