.Phase1Footer {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 24%) 100%
  );
  display: flex;
  font-weight: 500;
  gap: 16px;
  padding: 24px;

  .App--mobile & {
    align-items: stretch;
    flex-direction: column;
    gap: 11px;
    padding: 16px;
  }

  &-links {
    display: flex;
    justify-content: space-between;
  }

  &-infoLinks {
    display: flex;
    gap: 24px;
    justify-content: flex-end;

    .App--mobile & {
      gap: 16px;
    }
  }

  .App--desktop &-infoLinks,
  .App--desktop &-socialLinks {
    width: 200px;
  }

  &-infoLink {
    color: currentcolor;
    text-decoration: none;

    &:active {
      text-decoration: underline;
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-availabilityMessage {
    flex: 1;
    font-size: 11px;
    opacity: 0.6;
    text-align: center;
  }
}
