.Phase1FormResult {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .App--mobile & {
    gap: 8px;
    max-width: var(--maxWidth-mobile);
  }

  .App--desktop & {
    max-width: 448px;
  }

  &-title {
    font-family: var(--fontFamily-title);
    font-size: 42px;
    font-weight: 400;
    line-height: 0.8;
    margin: 0;
    text-transform: uppercase;

    .App--mobile & {
      font-size: 32px;
    }
  }

  .App--desktop &-text,
  .App--desktop &-title {
    max-width: 290px;
  }

  .App--mobile &-text {
    font-size: 13px;
  }
}
