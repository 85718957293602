.DiscoverLink {
  align-items: center;
  background-color: transparent;
  border: 1px solid rgb(255 255 255 / 50%);
  color: inherit;
  cursor: default;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  letter-spacing: 0.2px;
  line-height: 1;
  padding: 9px 16px;
  text-decoration: none;
  white-space: nowrap;
  width: fit-content;

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: var(--color-brandRed);
    }
  }

  &:active:not(:disabled),
  &:active:not(:disabled):hover {
    background-color: white;
    border-color: white;
    color: var(--color-brandRed);
  }
}
