.Polygon {
  &-wrapper {
    position: relative;

    [data-color='charcoal'] {
      fill: #121212;
    }

    [data-color='gold'] {
      fill: #efd782;
    }
  }

  &-outer {
    position: absolute;

    /* stylelint-disable-next-line  selector-class-pattern */
    &.isActive {
      stroke-width: 2px;
    }
  }

  &-inner {
    position: absolute;
    left: 8px;
    top: 8px;
    fill: white;
  }
}
