.Phase3SectionTrailer {
  align-items: center;
  background-image: url('./background.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: var(--app-headerHeight);

  .App--mobile & {
    background-position: right;
  }

  &-body {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;
  }

  &-title {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px currentcolor;
    font-family: var(--fontFamily-title);
    font-weight: 400;
    line-height: 0.8;
    margin: 0;
    text-align: center;
    text-transform: uppercase;

    .App--desktop & {
      font-size: 160px;
      grid-column: span 12;
    }

    .App--mobile & {
      font-size: 96px;
      grid-column: span 4;
    }
  }

  &--playing &-buttonWatch {
    display: none;
  }

  &-videoOverlay {
    align-items: center;
    background: black;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 10vh 10vw;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 300;

    &:not([hidden]) {
      display: flex;
    }
  }

  &-video {
    max-height: 100%;
    width: 100%;
  }

  &-buttonCloseVideo {
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    bottom: var(--app-gridGap);
    color: currentcolor;
    cursor: pointer;
    font-weight: 700;
    padding: 0 0 5px;
    position: absolute;
  }
}
