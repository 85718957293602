.Phase1Header {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  height: var(--app-headerHeight);
  padding-right: var(--app-gridGap);

  .App--mobile & {
    justify-content: space-between;
  }

  .App--desktop & {
    justify-content: flex-end;
  }

  &-logoContainer {
    height: 100%;
    position: relative;

    .App--desktop & {
      display: none;
    }
  }

  &-logoLink {
    height: 100%;
    display: block;
  }

  &-logo {
    aspect-ratio: auto 320 / 176;
    display: block;
    height: 100%;
  }

  &-timeline {
    align-items: center;
    display: flex;
    text-transform: uppercase;

    .App--desktop & {
      font-weight: 700;
      gap: 10px;
      margin-top: 37px;
    }

    .App--mobile & {
      font-size: 11px;
      font-weight: 500;
      gap: 16px;
      margin-top: 16px;
    }
  }

  &-timelineSeparator {
    background-color: currentcolor;
    height: 1px;
    width: 120px;

    .App--mobile & {
      width: 40px;
    }
  }
}
