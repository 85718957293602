.Phase3SectionThanks {
  align-items: center;
  background-image: url('./../background.jpg');
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  position: relative;

  &-body {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    grid-template-columns: var(--app-gridColumns);
    justify-content: center;
    max-width: var(--app-maxWidth);
    margin-inline: auto;
    padding: 0 var(--app-gridGap);
    width: 100%;

    .App--desktop & {
      gap: 65px;
    }

    .App--mobile & {
      gap: 48px;
    }
  }

  &-title {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px currentcolor;
    font-family: var(--fontFamily-title);
    font-weight: 400;
    line-height: 0.9;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: -0.16em;

    .App--desktop & {
      font-size: 320px;
    }

    .App--mobile & {
      font-size: 128px;
    }
  }

  &-text {
    font-family: var(--fontFamily-title);
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    text-transform: uppercase;

    .App--desktop & {
      max-width: 480px;
    }

    .App--mobile & {
      max-width: 270px;
    }
  }
}
