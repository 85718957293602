@import-normalize; // bring in normalize.css styles

body {
  background-color: var(--color-brandRed);
  color: white;
  font-family: var(--fontFamily-primary);
  font-weight: 500;
  line-height: 1;
  text-rendering: optimizelegibility;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  width: 100%;
}

a {
  color: currentcolor;
}

:focus:not(:focus-visible) {
  outline: none;
}
