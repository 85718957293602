.Phase1 {
  align-items: center;
  align-self: stretch;
  background-image: url('../background-monument.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 13px;
  letter-spacing: 0.002em;
  line-height: 1.4615;

  .App--desktop & {
    overflow: hidden;
  }

  .App--mobile & {
    background-attachment: fixed;
    background-position: top right 26.5%;
    font-size: 12px;
    gap: 3vh;
    overflow: auto;
  }

  &-footer,
  &-header {
    align-self: stretch;
    flex-shrink: 0;
  }

  .App--desktop &-header {
    position: fixed;
    width: 100%;
  }

  &-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .App--desktop & {
      gap: 16px;
      overflow: auto;
    }
  }

  &-content {
    flex: 1;
    margin: 0 auto;
    max-width: var(--app-maxWidth);
    width: 100%;
  }

  &-logoLink {
    max-width: 320px;
    position: relative;
    width: 25vw;

    .App--mobile & {
      display: none;
    }
  }

  &-logo {
    aspect-ratio: auto 320 / 176;
    display: block;
  }
}
