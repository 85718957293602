.Phase3Header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-right: var(--app-gridGap);

  .App--mobile & {
    font-size: 11px;
    font-weight: 500;
    height: var(--app-headerHeight);
  }

  &-logoContainer {
    height: 100%;
    position: relative;
  }

  &-logoLink {
    height: 100%;
    display: block;

    .App--desktop & {
      max-width: 320px;
      position: relative;
      width: 25vw;
      height: auto;
    }
  }

  &-logo {
    aspect-ratio: auto 320 / 176;
    display: block;
    height: 100%;
  }

  &-vertContainer {
    transition: opacity 0.4s linear;
    padding-top: var(--app-gridGap);
    width: 106px;
  }

  &--withoutVert &-vertContainer {
    opacity: 0;
    pointer-events: none;
  }

  &-menuButton {
    align-self: center;
    align-items: center;
    background: transparent;
    border: 0 none;
    color: inherit;
    display: flex;
    height: 18px;
    justify-content: center;
    outline: none;
    padding: 0;
    position: relative;
    width: 18px;
    z-index: 200;

    span {
      background: currentcolor;
      display: block;
      height: 2px;
      opacity: 1;
      position: absolute;
      right: 0;
      transform: rotate(0deg);
      transition: all 0.25s ease-in-out;
      width: 100%;

      &:nth-child(1) {
        top: 0;
        width: 12px;
      }

      &:nth-child(2) {
        top: 8px;
      }

      &:nth-child(3) {
        bottom: 0;
        width: 12px;
      }
    }
  }

  &--menuVisible &-menuButton span {
    &:nth-child(1) {
      top: 8px;
      transform: rotate(-45deg);
      width: 100%;
    }

    &:nth-child(2) {
      opacity: 0;
      width: 0;
    }

    &:nth-child(3) {
      bottom: 8px;
      transform: rotate(45deg);
      width: 100%;
    }
  }
}
