.LegalLinks {
  display: flex;
  gap: var(--app-gridGap);
  justify-content: flex-end;
  white-space: nowrap;

  &-link {
    color: currentcolor;
    text-decoration: none;

    &:active {
      text-decoration: underline;
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
