.Phase3SectionExperienceDesktop {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &-carousel {
    align-items: flex-end;
    display: flex;
    height: 292px;
    overflow: hidden;
  }

  &-slide {
    flex: 0 0 0;
    transition: all 0.5s ease-in-out;
    height: 100%;

    &--active {
      flex-basis: calc((100% - var(--app-gridGap)) / 3 * 2);
      margin-right: var(--app-gridGap);
    }

    &--halfHeight {
      height: 50%;
    }

    &--next {
      flex-basis: calc((100% - var(--app-gridGap)) / 3);
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }

  &-toolbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-text {
    font-family: var(--fontFamily-title);
    font-size: 24px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;
  }

  &-controlButtons {
    align-items: center;
    display: flex;
    gap: 12px;
  }

  &-controlButtonNext,
  &-controlButtonPrev {
    background-color: transparent;
    border: 0 none;
    color: currentcolor;
    padding: 0;

    & > * {
      display: block;
    }

    &:disabled {
      opacity: 0.38;
    }
  }
}
