.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &--desktop {
    --app-gridColumns: repeat(12, 1fr);
    --app-gridGap: var(--gridGap-desktop);
    --app-headerHeight: 99px;
    --app-maxWidth: var(--maxWidth-desktop);
  }

  &--mobile {
    --app-gridColumns: repeat(4, 1fr);
    --app-gridGap: var(--gridGap-mobile);
    --app-headerHeight: 66px;
    --app-maxWidth: var(--maxWidth-mobile);
  }
}
