.Phase2SectionRegistration {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  position: relative;

  &::before {
    content: '';
    background-image: url('./background.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .App--desktop & {
      // background-attachment: fixed;
      background-position: center;
    }

    .App--mobile & {
      background-position: top left 45.5%;
      transform: scaleX(-1);
    }
  }

  &-gradient {
    align-items: center;
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 46.1%,
      var(--color-brandRed) 100%
    );
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: var(--app-headerHeight);
    z-index: 0;
  }

  &-body {
    align-items: end;
    box-sizing: border-box;
    display: grid;
    flex: 1;
    gap: var(--app-gridGap);
    grid-template-columns: var(--app-gridColumns);
    max-width: var(--app-maxWidth);
    padding: 0 var(--app-gridGap);
    width: 100%;

    .App--desktop & {
      padding-bottom: 12.963vh;
    }
  }

  &-areaDescription {
    .App--desktop & {
      display: flex;
      flex-direction: column;
      gap: 16px;
      grid-column: 2 / span 5;
    }

    .App--mobile & {
      display: none;
    }
  }

  &-areaForm {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .App--desktop & {
      grid-column: 8 / span 4;
    }

    .App--mobile & {
      align-self: center;
      grid-column: span 4;
    }
  }

  &-descriptionTitle {
    font-family: var(--fontFamily-title);
    font-size: 96px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;
  }

  &-descriptionText {
    max-width: 400px;
  }
}
